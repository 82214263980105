import React from 'react';
import ErrorScreen from './errorScreen';

interface ErrorBoundaryState {
    error: boolean;
    ex?: Error;
}

// eslint-disable-next-line @typescript-eslint/ban-types
class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: false,
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error(error);
        this.setState({ error: true, ex: error });
    }

    render(): React.ReactNode {
        if (this.state.error) {
            return (
                <React.Fragment>
                    <ErrorScreen />
                </React.Fragment>
            );
        }

        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
}

export default ErrorBoundary;
