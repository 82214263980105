import React from 'react';
import { getLoadingLabel } from 'translations/utilities';
import Spinner, { ClipSpinner } from '../spinner';

interface LoadingProps {
    size?: number | string;
    hideText?: boolean;
    classname?: string;
}

const loading = ({ size, hideText, classname }: LoadingProps) => {
    return (
        <Spinner
            text={hideText ? undefined : `${getLoadingLabel()}...`}
            className={classname}
        >
            <ClipSpinner size={size ?? 70} />
        </Spinner>
    );
};

export default loading;
