import { getLanguage } from 'react-multi-lang';

export enum AvailableLanguages {
    En = 'enEn', // must be same as imported module name
    Hr = 'hrHr',
}

export const defaultLanguage = AvailableLanguages.Hr;

const languageStorageKey = 'language';
export const getLanguageFromStorage = () => {
    return localStorage.getItem(languageStorageKey);
};

export const setLanguageInStorage = (newVal: string) => {
    localStorage.setItem(languageStorageKey, newVal);
};

export const getDateLocaleStringValue = () => {
    switch (getLanguage()) {
        case AvailableLanguages.Hr:
            return 'hr-HR';
        case AvailableLanguages.En:
            return 'en-EN';
        default:
            return undefined;
    }
};

export const getAcceptLanguageHeaderValue = (val: string) => {
    switch (val) {
        case AvailableLanguages.Hr:
            return 'hr';
        case AvailableLanguages.En:
            return 'en';
        default:
            throw new Error(`Language: ${val.toString()} not supported`);
    }
};

export const getLocaleValueForMomentInitialization = (val: AvailableLanguages) => {
    switch (val) {
        case AvailableLanguages.En:
            return 'en';
        case AvailableLanguages.Hr:
            return 'hr';
        default:
            throw new Error(`Language ${val} not supported`);
    }
};

export const getLoadingLabel = () => {
    const language = getLanguageFromStorage() ?? defaultLanguage;
    switch (language) {
        case AvailableLanguages.En:
            return 'Loading';
        case AvailableLanguages.Hr:
            return 'Učitavanje';
        default:
            return 'Učitavanje';
    }
};
