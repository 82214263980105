import * as React from 'react';

import './clipSpinner.scss';

export interface ClipSpinnerProps {
    size?: number | string;
}

export default ({ size }: ClipSpinnerProps) => {
    return (
        <div
            className={'clip-spinner'}
            style={{
                width: size,
                height: size,
            }}
        />
    );
};
