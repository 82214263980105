import Error from 'assets/icons/error.svg';
import React from 'react';
import { useTranslation } from 'react-multi-lang';
import './errorScreen.scss';

const errorScreen = () => {
    const t = useTranslation();

    return (
        <div className="error-screen">
            <img src={Error} alt={'error banner'} />
            <div className="error-screen__title">{t('components-common.error')}</div>
        </div>
    );
};

export default errorScreen;
