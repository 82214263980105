import ErrorBoundary from 'components/errorBoundary/errorBoundary';
import Loading from 'components/loading/loading';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';

const App = React.lazy(() => import('containers/app/app'));

export const isDevelopment = process.env.NODE_ENV === 'development';

const getEntryElement = () => document.getElementById('app');
const root = createRoot(getEntryElement()!);

root.render(
    <ErrorBoundary>
        <Suspense fallback={<Loading />}>
            <App />
        </Suspense>
    </ErrorBoundary>
);
